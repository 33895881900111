<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Manual Exit Category</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('category_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create New Category
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                <v-btn-toggle class="col-12 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? page * 100 : 1}} - {{page > 1 ? items.length + 100 : items.length}} of {{items.length}}</span></v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ID}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('category_update')">
                                <template v-slot:activator="{ on }">
                                <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'update')">{{props.item.CategoryName}}</a>
                                </template>
                                <span>Update Category</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'view')">{{props.item.CategoryName}}</a>
                                </template>
                                <span>View Category</span>
                                </v-tooltip>
                            </td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>{{props.item.UpdatedDateTime}}</td>
                            <!--
                            <td v-if="validateAccess('system_delete')">
                                <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                <span>
                                    <a v-on="on" id="custom-hover" @click.prevent="viewMore(props.item, 'delete')"><v-icon color="red" right x-small>fa fa-trash</v-icon></a>
                                </span>
                                </template>
                                <span>Remove System</span>
                                </v-tooltip>
                            </td>
                            -->
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @delete="onDelete" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateCategory';
import edit from '.././modals/UpdateCategory';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Manual Exit Category',
    title: 'Manual Exit Category',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Administration',
                disabled: true,
                href: '#',
                },

                {
                text: 'Parking Entry',
                disabled: true,
                href: '#',
                },

                {
                text: 'Manual Exit Category',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'ID', value: 'SystemID', width: '3%' },
                { text: 'Category', value: 'SystemRef', width: '3%' },
                { text: 'Created Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Updated Date', value: 'UpdatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            services: []
        }
    },

    components: {create, edit, alert, alertbox2},

    watch: {
        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },
    },

    async created(){
        this.getSystem();
    },

    methods: {
        async getSystem(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getManualExitCategory", {search: this.search});

                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.count;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].CreatedDateTime = dt[i].CreatedDateTime == null || dt[i].CreatedDateTime == undefined ? "N/A" : moment(new Date(dt[i].CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].UpdatedDateTime = dt[i].UpdatedDateTime == null || dt[i].UpdatedDateTime == undefined ? "N/A" : moment(new Date(dt[i].UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].CreatedByAdminID = dt[i].CreatedByAdminID || 'N/A';
                    dt[i].CreatedByAdminName = dt[i].CreatedByAdminName || 'N/A';
                    dt[i].UpdatedByAdminID = dt[i].UpdatedByAdminID || 'N/A';
                    dt[i].UpdatedByAdminName = dt[i].UpdatedByAdminName || 'N/A';
                    //replace(/\s/g,'') remove any literal whitespace
                }

                this.items = dt.filter(itm => itm.DeletedDateTime === null);
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addSystem(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {CategoryName: v[0].category};
                let response = await this.$store.dispatch("createCategory", req);
                if (response.data.code === 'AP000'){
                    await this.getSystem();
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Category Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This category is already created`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.tableloading = false;
                this.btn_loading = false;
            }
        },

        async categoryRemove(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("deleteCategory", {ID: this.ma.modalInfo.ID})
                if (response.data.code === 'AP000'){
                        await this.getSystem();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Category has been removed!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to remove. This is currently being used!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async systemUpdate(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updateCategory", {CategoryName: v[0].category, ID: this.ma.modalInfo.ID});
                if (response.data.code === 'AP000'){
                        await this.getSystem();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Category has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update.`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewSOC(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin SOC", params: {id: row.userID}, query: {email: row.email}});
        },

        onDelete(){
            this.ma.modal_update = false;
            this.categoryRemove();
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addSystem(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.systemUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.categoryRemove();
                }
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getSystem();
          }, 800);
        }
    }
}
</script>