<template>
   <div>
      <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
      </v-breadcrumbs>
      <v-card class="mx-auto" outlined tile color="#F8F8FF">
         <v-card-title>Holiday</v-card-title>
         <v-container fluid>
            <v-row class="fill-height">
               <v-overlay
                  :absolute="true"
                  :opacity="1"
                  :z-index="999"
                  :value="loading"
                  color="#D2D7DF"
                  >
                  <v-progress-circular indeterminate size="100"></v-progress-circular>
               </v-overlay>
               <v-col>
                  <v-sheet height="64">
                     <v-toolbar flat color="white">
                        <v-btn small outlined class="ml-4" @click="setToday">
                           Today
                        </v-btn>
                        <v-btn fab text small @click="prev">
                           <v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn fab text small @click="next">
                           <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                        <!-- <v-toolbar-title>{{ curDatetoMomentMonthYear() }}</v-toolbar-title> -->
						<v-autocomplete
						dense
						label="Month"
						v-model="setMonth"
						:items="month.items"
						item-text="name"
						item-value="id"
						hide-details
						>
						</v-autocomplete>
						<v-autocomplete
						dense
						label="Year"
						v-model="setYear"
						:items="year.items"
						item-text="id"
						item-value="id"
						hide-details
						class="ml-4"
						>
						</v-autocomplete>
						<v-btn small color="primary" class="ml-4" @click="setCurrentDate()">Change</v-btn>
						<v-btn v-if="type != 'month'" small outlined class="ml-4" @click="type = 'month'">Month View</v-btn>
                        <div class="flex-grow-1"></div>
                        <v-spacer></v-spacer>
                        <span>
                            <v-chip
                            class="ma-2"
                            color="blue"
                            label
                            small
                            text-color="white"
                            >
                            <v-icon x-small left>
                                mdi-label
                            </v-icon>
                            National
                            </v-chip>
                            <v-chip
                            class="ma-2"
                            color="cyan"
                            label
                            small
                            text-color="white"
                            >
                            <v-icon x-small left>
                                mdi-label
                            </v-icon>
                            State
                            </v-chip>
                            <v-chip
                            class="ma-2"
                            color="purple"
                            label
                            small
                            text-color="white"
                            >
                            <v-icon x-small left>
                                mdi-label
                            </v-icon>
                            City
                            </v-chip>
                        </span>
                        <!--
                           <v-menu bottom right>
                             <template v-slot:activator="{ on }">
                               <v-btn outlined v-on="on">
                                 <span>{{ typeToLabel[type] }}</span>
                                 <v-icon right>mdi-menu-down</v-icon>
                               </v-btn>
                             </template>
                             <v-list>
                               <v-list-item @click="type = 'day'">
                                 <v-list-item-title>Day</v-list-item-title>
                               </v-list-item>
                               <v-list-item @click="type = 'week'">
                                 <v-list-item-title>Week</v-list-item-title>
                               </v-list-item>
                               <v-list-item @click="type = 'month'">
                                 <v-list-item-title>Month</v-list-item-title>
                               </v-list-item>
                               <v-list-item @click="type = '4day'">
                                 <v-list-item-title>4 days</v-list-item-title>
                               </v-list-item>
                             </v-list>
                           </v-menu>
                           -->
                     </v-toolbar>
                  </v-sheet>
                  <v-dialog v-model="dialogDate" max-width="500">
                     <v-card>
                        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
                           Add Holiday for {{curDatetoMoment()}}
                           <v-btn text @click="dialogDate = !dialogDate" fab depressed small>
                              <v-icon small color="white">fas fa-times</v-icon>
                           </v-btn>
                        </v-card-title>
                        <v-form ref="ct" @submit.prevent="addEvent">
                           <v-card-text>
                              <v-container>
                                 <v-row>
                                    <v-col cols="12" md="12">
                                       <v-autocomplete
                                          dense
                                          outlined
                                          placeholder="Select"
                                          label="Holiday Type"
                                          v-model="holiday.selected"
                                          @change="execFilter('holiday')"
                                          :items="holiday.items"
                                          item-text="name"
                                          item-value="id"
                                          :rules="holiday.rule"
                                          hide-details="auto"
                                          >
                                       </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                       <v-autocomplete
                                          dense
                                          v-model="form[0].selected"
                                          :items="form[0].country"
                                          :rules="form[0].rule"
                                          item-text="name"
                                          item-value="CountryCode"
                                          @change="execFilter('country')"
                                          label="Country"
                                          outlined
                                          hide-details="auto"
                                          ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="holiday.selected == '2' || holiday.selected == '3'">
                                       <v-autocomplete
                                          dense
                                          v-model="form[1].selected"
                                          :items="form[1].state"
                                          :rules="form[1].rule"
                                          item-text="name"
                                          item-value="StateCode"
                                          @change="execFilter('state')"
                                          label="State"
                                          outlined
                                          hide-details="auto"
                                          ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12" v-if="holiday.selected == '3'">
                                       <v-autocomplete
                                          dense
                                          v-model="form[2].selected"
                                          :items="form[2].city"
                                          :rules="form[2].rule"
                                          item-text="name"
                                          item-value="CityCode"
                                          label="City"
                                          outlined
                                          hide-details="auto"
                                          ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                       <v-text-field dense outlined v-model="name" :rules="namerule" type="text" label="Holiday Title *"></v-text-field>
                                    </v-col>
                                 </v-row>
                                 <!--
                                    <v-text-field v-model="start" type="date" label="start (required)"></v-text-field>
                                    <v-text-field v-model="end" type="date" label="end (required)"></v-text-field>
                                    <v-text-field v-model="color" type="color" label="color (click to open color menu)"></v-text-field>
                                    -->
                              </v-container>
                           </v-card-text>
                           <v-divider></v-divider>
                           <v-card-actions>
                              <v-btn tile type="submit" small color="primary" @click.stop="dialog = false">
                                 <v-icon left x-small dark>fa fa-save</v-icon>
                                 Save
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn tile color="blue darken-1" text @click="dialogDate = !dialogDate">Close</v-btn>
                           </v-card-actions>
                        </v-form>
                     </v-card>
                  </v-dialog>
                  <v-sheet height="600">
                     <v-calendar
                     ref="calendar"
                     v-model="focus"
                     color="primary"
                     :events="events"
                     :event-color="getEventColor"
                     :event-margin-bottom="3"
                     :now="today"
                     :type="type"
                     @click:event="showEvent"
                     @click:more="viewDay"
                     @click:date="setDialogDate"
                     @change="updateRange"
                     ></v-calendar>
                     <v-menu
                        v-model="selectedOpen"
                        :close-on-content-click="false"
                        :activator="selectedElement"
                        full-width
                        offset-x
                        >
                        <v-card :width="550" flat>
                           <v-card-title class="justify-space-between" :style="{'background-color': selectedEvent.color}" style="color: white; padding-top: 5px; padding-bottom: 5px">
                              Edit Holiday for {{selectedEvent.DayName}}
                              <v-btn text @click="selectedOpen = false" fab depressed small>
                                 <v-icon small color="white">fas fa-times</v-icon>
                              </v-btn>
                           </v-card-title>
                           <!--
                              <v-toolbar :color="selectedEvent.color" dark>
                                <v-btn @click="deleteEvent(selectedEvent.id)" icon>
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                <div class="flex-grow-1"></div>
                              </v-toolbar>
                              -->
                           <v-form ref="et" @submit.prevent="updateEvent(selectedEvent)">
                              <v-card-text>
                                 <!--
                                    <form v-if="currentlyEditing !== selectedEvent.id">
                                      {{ selectedEvent.details }}
                                    </form>
                                    -->
                                 <v-container class="fill-height"
                                    fluid >
                                    <v-row>
									   <v-col cols="12" md="12">
                                          <v-text-field dense outlined v-model="selectedEvent.ID" type="text" label="ID" disabled hide-details="auto"></v-text-field>
                                       </v-col>
                                       <v-col cols="12" md="12">
                                          <v-autocomplete
                                             dense
                                             outlined
                                             placeholder="Select"
                                             label="Holiday Type"
                                             v-model="holidayedit.selected"
                                             @change="execFilterforEdit('holiday')"
                                             :items="holidayedit.items"
                                             item-text="name"
                                             item-value="id"
                                             :rules="holidayedit.rule"
                                             hide-details="auto"
                                             autocomplete="off"
                                             >
                                          </v-autocomplete>
                                       </v-col>
                                       <v-col cols="12" md="12">
                                          <v-autocomplete
                                             dense
                                             v-model="formedit[0].selected"
                                             :items="formedit[0].country"
                                             :rules="formedit[0].rule"
                                             item-text="name"
                                             item-value="CountryCode"
                                             @change="execFilterforEdit('country')"
                                             label="Country"
                                             outlined
                                             hide-details="auto"
                                             autocomplete="off"
                                             ></v-autocomplete>
                                       </v-col>
                                       <v-col cols="12" md="12" v-if="holidayedit.selected == '2' || holidayedit.selected == '3'">
                                          <v-autocomplete
                                             dense
                                             v-model="formedit[1].selected"
                                             :items="formedit[1].state"
                                             :rules="formedit[1].rule"
                                             item-text="name"
                                             item-value="StateCode"
                                             @change="execFilterforEdit('state')"
                                             label="State"
                                             outlined
                                             hide-details="auto"
                                             autocomplete="off"
                                             ></v-autocomplete>
                                       </v-col>
                                       <v-col cols="12" md="12" v-if="holidayedit.selected == '3'">
                                          <v-autocomplete
                                             dense
                                             v-model="formedit[2].selected"
                                             :items="formedit[2].city"
                                             :rules="formedit[2].rule"
                                             item-text="name"
                                             item-value="CityCode"
                                             label="City"
                                             outlined
                                             hide-details="auto"
                                             autocomplete="off"
                                             ></v-autocomplete>
                                       </v-col>
                                       <v-col cols="12" md="12">
                                          <v-text-field dense outlined v-model="nameedit" :rules="nameeditrule" type="text" label="Holiday Title *" hide-details="auto"></v-text-field>
                                       </v-col>
                                       <v-col cols="12" md="12">
                                          <v-text-field dense outlined v-model="CreatedDateTime" type="text" label="Created Date" disabled hide-details="auto"></v-text-field>
                                       </v-col>
                                       <v-col cols="12" md="12">
                                          <v-text-field dense outlined v-model="UpdatedDateTime" type="text" label="Updated Date" disabled hide-details="auto"></v-text-field>
                                       </v-col>
                                       <v-col cols="12" sm="12" md="12">
                                            <span class="text-body-2">Status</span>
                                            <v-switch
                                            color="success"
                                            class="col-1 pl-0"
                                            v-model="formedit[3].status"
                                            :label="`${formedit[3].status ? 'Enabled' : 'Disabled'}`"
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                    <!--
                                       <textarea-autosize
                                       v-model="selectedEvent.details"
                                       type="text"
                                       style="width: 100%"
                                       :min-height="100"
                                       placeholder="add note">
                                       </textarea-autosize>
                                       -->
                                 </v-container>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                 <v-btn tile type="submit" small color="primary">
                                    <v-icon left x-small dark>fa fa-save</v-icon>
                                    Save
                                 </v-btn>
                                 <v-btn v-if="validateAccess('calendar_delete')" @click="deleteEvent(selectedEvent.ID)" tile small color="error" type="button">
                                    <v-icon left x-small>fa fa-trash</v-icon>
                                    Delete
                                 </v-btn>
                                 <v-spacer></v-spacer>
                                 <v-btn tile color="blue darken-1" text @click="selectedOpen = false">Close</v-btn>
                                 <!--
                                    <v-btn v-if="currentlyEditing !== selectedEvent.id" text @click.prevent="editEvent(selectedEvent)">
                                      edit
                                    </v-btn>
                                    -->
                              </v-card-actions>
                           </v-form>
                        </v-card>
                     </v-menu>
                  </v-sheet>
                  <v-snackbar
                     v-model="swal.notification"
                     top
                     :color="swal.scolor"
                     >
                     {{ swal.message }}
                  </v-snackbar>
               </v-col>
            </v-row>
         </v-container>
      </v-card>
   </div>
</template>

<script>
import {
	ERR
} from '../../assets/js/errhandle.js';
import * as moment from "moment";
import {rule} from '../../assets/js/checkPerm';
export default {
	name: 'Holiday',
	title: 'Holiday',

	data: () => ({
		crumbs: [{
				text: 'Home',
				disabled: true,
				href: '#',
			},

			{
				text: 'Administration',
				disabled: true,
				href: '#',
			},

			{
				text: 'Holiday',
				disabled: true,
				href: '#',
			},
		],
		today: moment(new Date()).format('YYYY-MM-DD'),
		focus: moment(new Date()).format('YYYY-MM-DD'),
		type: 'month',
		typeToLabel: {
			month: 'Month',
			week: 'Week',
			day: 'Day',
			'4day': '4 Days',
		},
		holiday: {
			items: [{
				id: '1',
				name: 'National'
			}, {
				id: '2',
				name: 'State'
			}, {
				id: '3',
				name: 'City'
			}],
			selected: '',
			rule: []
		},
		holidayedit: {
			items: [{
				id: '1',
				name: 'National'
			}, {
				id: '2',
				name: 'State'
			}, {
				id: '3',
				name: 'City'
			}],
			selected: '',
			rule: []
		},
		form: [{
				country: [],
				itemsFull: [],
				rule: [],
				selected: ''
			},
			{
				state: [],
				itemsFull: [],
				rule: [],
				selected: ''
			},
			{
				city: [],
				itemsFull: [],
				rule: [],
				selected: ''
			},
			{
				postcode: '',
				rule: []
			},
		],
		formedit: [{
				country: [],
				itemsFull: [],
				rule: [],
				selected: ''
			},
			{
				state: [],
				itemsFull: [],
				rule: [],
				selected: ''
			},
			{
				city: [],
				itemsFull: [],
				rule: [],
				selected: ''
			},
			{
				postcode: '',
				rule: []
			},
            {
				status: '',
			},
		],
		name: null,
		namerule: [],
		nameedit: null,
		nameeditrule: [],
		details: null,
		start: null,
		end: null,
		color: '#1976D2', // default event color
		currentlyEditing: null,
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		events: [],
		dialog: false,
		dialogDate: false,
		swal: {
			notification: false,
			message: '',
			scolor: '',
		},
		loading: false,
		month: {
			items: [
				{id: 'Jan', name: 'January'},
				{id: 'Feb', name: 'February'},
				{id: 'Mar', name: 'March'},
				{id: 'Apr', name: 'April'},
				{id: 'May', name: 'May'},
				{id: 'Jun', name: 'June'},
				{id: 'Jul', name: 'July'},
				{id: 'Aug', name: 'August'},
				{id: 'Sep', name: 'September'},
				{id: 'Oct', name: 'October'},
				{id: 'Nov', name: 'November'},
				{id: 'Dec', name: 'December'},
			],
			selected: ''
		},
		year: {
			items: [],
			selected: ''
		},
		CreatedDateTime: '',
		UpdatedDateTime: ''
	}),
	async mounted() {
		await this.getAllCountry();
		await this.getAllState();
		await this.getAllCity();
		this.getEvents();
		for (let i=2021; i<2100; i++){
			this.year.items.push({id: i});
		}
	},
	computed: {
		title() {
			const {
				start,
				end
			} = this
			if (!start || !end) {
				return ''
			}
			const startMonth = this.monthFormatter(start)
			const endMonth = this.monthFormatter(end)
			const suffixMonth = startMonth === endMonth ? '' : endMonth
			const startYear = start.year
			const endYear = end.year
			const suffixYear = startYear === endYear ? '' : endYear
			const startDay = start.day + this.nth(start.day)
			const endDay = end.day + this.nth(end.day)
			switch (this.type) {
				case 'month':
					return `${startMonth} ${startYear}`
				case 'week':
				case '4day':
					return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
				case 'day':
					return `${startMonth} ${startDay} ${startYear}`
			}
			return ''
		},
		monthFormatter() {
			return this.$refs.calendar.getFormatter({
				timeZone: 'UTC',
				month: 'long',
			})
		},

		setMonth:{
			get()
			{
				this.month.selected = moment(this.focus).format('MMM');
				return moment(this.focus).format('MMM');
			},
			set(value)
			{
				this.month.selected = value;
			}
		},

		setYear:{
			get()
			{
				this.year.selected = Number(moment(this.focus).format('YYYY'));
				return Number(moment(this.focus).format('YYYY'));
			},
			set(value)
			{
				this.year.selected = value;
			}
		},
	},
	methods: {
		async getAllCountry() {
			let list = [];
			try {
				let response = await this.$store.dispatch("listCountry", {
					search: ''
				});
				let dt = response.data.record;
				this.form[0].country.push({
					name: "Select",
					CountryCode: ""
				});
				this.form[0].itemsFull.push({
					name: "Select",
					CountryCode: ""
				});

				this.formedit[0].country.push({
					name: "Select",
					CountryCode: ""
				});
				this.formedit[0].itemsFull.push({
					name: "Select",
					CountryCode: ""
				});
				if (response.data.code == "AP000") {
					for (var i = 0; i < dt.length; i++) {
						dt[i].name = `${dt[i].CountryCode} - ${dt[i].CountryName}`;

						this.form[0].country.push(dt[i]);
						this.form[0].itemsFull.push(dt[i]);

						this.formedit[0].country.push(dt[i]);
						this.formedit[0].itemsFull.push(dt[i]);
					}
				}

				list = dt;
			} catch (err) {
				console.log(err);
			}

			return list;
		},

		async getAllState() {
			let list = [];
			try {
				let response = await this.$store.dispatch("listState", {
					search: ''
				});
				let dt = response.data.record;
				this.form[1].state.push({
					name: "Select",
					StateCode: ""
				});
				this.form[1].itemsFull.push({
					name: "Select",
					StateCode: ""
				});

				this.formedit[1].state.push({
					name: "Select",
					StateCode: ""
				});
				this.formedit[1].itemsFull.push({
					name: "Select",
					StateCode: ""
				});
				if (response.data.code == "AP000") {
					for (var i = 0; i < dt.length; i++) {
						dt[i].name = `${dt[i].StateCode} - ${dt[i].StateName}`;

						this.form[1].state.push(dt[i]);
						this.form[1].itemsFull.push(dt[i]);

						this.formedit[1].state.push(dt[i]);
						this.formedit[1].itemsFull.push(dt[i]);
					}
				}

				list = dt;
			} catch (err) {
				console.log(err);
			}

			return list;
		},

		async getAllCity() {
			let list = [];
			try {
				let response = await this.$store.dispatch("listCity", {
					search: ''
				});
				let dt = response.data.record;
				this.form[2].city.push({
					name: "Select",
					CityCode: ""
				});
				this.form[2].itemsFull.push({
					name: "Select",
					CityCode: ""
				});

				this.formedit[2].city.push({
					name: "Select",
					CityCode: ""
				});
				this.formedit[2].itemsFull.push({
					name: "Select",
					CityCode: ""
				});
				if (response.data.code == "AP000") {
					for (var i = 0; i < dt.length; i++) {
						dt[i].name = `${dt[i].CityCode} - ${dt[i].CityName}`;

						this.form[2].city.push(dt[i]);
						this.form[2].itemsFull.push(dt[i]);

						this.formedit[2].city.push(dt[i]);
						this.formedit[2].itemsFull.push(dt[i]);
					}
				}

				list = dt;
			} catch (err) {
				console.log(err);
			}

			return list;
		},

		async getEvents() {
			let response = await this.$store.dispatch("getCalendarValues", {});
			let snapshot = response.data.record;
			const events = []
			snapshot.forEach(doc => {
				let appData = doc;
				let color = '';
				let holidaytype = '';
				if (doc.StateCode == null && doc.CityCode == null){
					color = 'blue';
					holidaytype = this.form[0].itemsFull.find(itm => itm.CountryCode === doc.CountryCode).CountryName;
				}
				if (doc.StateCode != null){
					color = 'cyan';
					holidaytype = this.form[1].itemsFull.find(itm => itm.StateCode === doc.StateCode).StateName;
				}
				if (doc.CityCode != null){
					color = 'purple';
					holidaytype = this.form[2].itemsFull.find(itm => itm.CityCode === doc.CityCode).CityName;
				}
				appData.id = doc.ID;
				appData.name = `${holidaytype} - ${doc.DayName}`;
				appData.start = new Date(doc.DayDate);
				appData.end = new Date(doc.DayDate);
				appData.color = color;
				events.push(appData);
			})
			this.events = events
		},
		setDialogDate({
			date
		}) {
			if (this.validateAccess('calendar_add')){
				this.dialogDate = true
				this.focus = date
				this.holiday.selected = "1";
				//this.getAllCountry();
				//this.getAllState();
				//this.getAllCity();
				this.form[0].selected = '';
				this.form[1].selected = '';
				this.form[2].selected = '';

				this.namerule = [];
				this.form[0].rule = [];
				this.form[1].rule = [];
				this.form[2].rule = [];
			}
		},
		execFilter(itm) {
			if (itm == 'holiday') {
				if (this.holiday.selected == '1') {
					this.form[1].selected = '';
					this.form[2].selected = '';
				}

				if (this.holiday.selected == '2') {
					this.form[2].selected = '';
				}
			}

			if (itm == 'country') {
				if (true) {
					let self = this;
					if (self.form[0].selected != '') {
						let itm = this.form[1].itemsFull.filter(item => {
							return item.CountryCode == self.form[0].selected;
						});

						this.form[1].state = [];
						this.form[1].selected = '';

						this.form[1].state.push({
							name: "Select State",
							StateCode: ""
						});
						for (var i = 0; i < itm.length; i++) {
							itm[i].name = `${itm[i].StateCode} - ${itm[i].StateName}`;
							this.form[1].state.push({
								name: itm[i].name,
								StateCode: itm[i].StateCode
							});
						}
					} else {
						this.form[1].state = [];
						this.form[1].selected = '';
						this.form[1].state = this.form[1].itemsFull;
					}
				}

				if (true) {
					let self = this;
					if (self.form[0].selected != '') {
						let itm = this.form[2].itemsFull.filter(item => {
							return item.CountryCode == self.form[0].selected;
						});

						this.form[2].city = [];
						this.form[2].selected = '';

						this.form[2].city.push({
							name: "Select City",
							CityCode: ""
						});
						for (var i = 0; i < itm.length; i++) {
							itm[i].name = `${itm[i].CityCode} - ${itm[i].CityName}`;
							this.form[2].city.push({
								name: itm[i].name,
								CityCode: itm[i].CityCode
							});
						}
					} else {
						this.form[2].city = [];
						this.form[2].selected = '';
						this.form[2].city = this.form[2].itemsFull;
					}
				}
			}

			if (itm == 'state') {
				let self = this;
				if (self.form[1].selected != '') {
					let itm = this.form[2].itemsFull.filter(item => {
						return item.StateCode == self.form[1].selected;
					});

					this.form[2].city = [];
					this.form[2].selected = '';

					this.form[2].city.push({
						name: "Select City",
						CityCode: ""
					});
					for (var i = 0; i < itm.length; i++) {
						itm[i].name = `${itm[i].CityCode} - ${itm[i].CityName}`;
						this.form[2].city.push({
							name: itm[i].name,
							CityCode: itm[i].CityCode
						});
					}
				} else {
					this.form[2].city = [];
					this.form[2].selected = '';
					this.form[2].city = this.form[2].itemsFull;
				}
			}
		},
		execFilterforEdit(itm) {
			if (itm == 'holiday') {
				if (this.holidayedit.selected == '1') {
					this.formedit[1].selected = '';
					this.formedit[2].selected = '';
				}

				if (this.holidayedit.selected == '2') {
					this.formedit[2].selected = '';
				}
			}

			if (itm == 'country') {
				if (true) {
					let self = this;
					if (self.formedit[0].selected != '') {
						let itm = this.formedit[1].itemsFull.filter(item => {
							return item.CountryCode == self.formedit[0].selected;
						});

						this.formedit[1].state = [];
						this.formedit[1].selected = '';

						this.formedit[1].state.push({
							name: "Select State",
							StateCode: ""
						});
						for (var i = 0; i < itm.length; i++) {
							itm[i].name = `${itm[i].StateCode} - ${itm[i].StateName}`;
							this.formedit[1].state.push({
								name: itm[i].name,
								StateCode: itm[i].StateCode
							});
						}
					} else {
						this.formedit[1].state = [];
						this.formedit[1].selected = '';
						this.formedit[1].state = this.formedit[1].itemsFull;
					}
				}

				if (true) {
					let self = this;
					if (self.formedit[0].selected != '') {
						let itm = this.formedit[2].itemsFull.filter(item => {
							return item.CountryCode == self.formedit[0].selected;
						});

						this.formedit[2].city = [];
						this.formedit[2].selected = '';

						this.formedit[2].city.push({
							name: "Select City",
							CityCode: ""
						});
						for (var i = 0; i < itm.length; i++) {
							itm[i].name = `${itm[i].CityCode} - ${itm[i].CityName}`;
							this.formedit[2].city.push({
								name: itm[i].name,
								CityCode: itm[i].CityCode
							});
						}
					} else {
						this.formedit[2].city = [];
						this.formedit[2].selected = '';
						this.formedit[2].city = this.formedit[2].itemsFull;
					}
				}
			}

			if (itm == 'state') {
				let self = this;
				if (self.formedit[1].selected != '') {
					let itm = this.formedit[2].itemsFull.filter(item => {
						return item.StateCode == self.formedit[1].selected;
					});

					this.formedit[2].city = [];
					this.formedit[2].selected = '';

					this.formedit[2].city.push({
						name: "Select City",
						CityCode: ""
					});
					for (var i = 0; i < itm.length; i++) {
						itm[i].name = `${itm[i].CityCode} - ${itm[i].CityName}`;
						this.formedit[2].city.push({
							name: itm[i].name,
							CityCode: itm[i].CityCode
						});
					}
				} else {
					this.formedit[2].city = [];
					this.formedit[2].selected = '';
					this.formedit[2].city = this.formedit[2].itemsFull;
				}
			}
		},
		viewDay({
			date
		}) {
			this.focus = date
			this.type = 'day'
		},
		getEventColor(event) {
			return event.color
		},
		setToday() {
			this.focus = this.today
		},
		prev() {
			this.$refs.calendar.prev()
		},
		next() {
			this.$refs.calendar.next()
		},
		async addEvent() {
			this.namerule = [
				v => !!v || 'This is required'
			];

			this.form[0].rule = [
				v => !!v || 'Please select Country'
			];

			if (this.holiday.selected == '2' || this.holiday.selected == '3') {
				this.form[1].rule = [
					v => !!v || 'Please select State'
				];
			}

			if (this.holiday.selected == '3') {
				this.form[2].rule = [
					v => !!v || 'Please select City'
				];
			}

			//must set this in order to use 'this' as 'self' inside settimeout etc..
			let self = this;

			setTimeout(async function () {
				if (self.$refs.ct.validate()) {
					self.loading = true;

					let req = {
						countrycode: self.form[0].selected == '' || self.holiday.selected === '2' || self.holiday.selected === '3' ? null : self.form[0].selected,
						statecode: self.form[1].selected == '' || self.holiday.selected === '3' ? null : self.form[1].selected,
						citycode: self.form[2].selected == '' ? null : self.form[2].selected,
						dayname: self.name,
						daytype: 'PH',
						daydate: self.focus
					};

					let response = await self.$store.dispatch("createCalendar", req);
					if (response.data.code === 'AP000') {
						self.getEvents()
						self.name = '',
							self.details = '',
							self.start = '',
							self.end = '',
							self.color = ''

						self.swal.notification = true;
						self.swal.scolor = 'green';
						self.swal.message = `${ERR.HANDLE(response.data.code)}: Holiday Created!`;
						self.dialogDate = false;
					}

					self.loading = false;
				}
			});
		},
		editEvent(ev) {
			this.currentlyEditing = ev.id
		},
		async updateEvent(ev) {
			this.nameeditrule = [
				v => !!v || 'This is required'
			];

			//must set this in order to use 'this' as 'self' inside settimeout etc..
			let self = this;

			setTimeout(async function () {
				if (self.$refs.et.validate()) {
					self.loading = true;
					
					let req = {
						calendardayid: ev.ID,
						countrycode: self.form[0].selected == '' || self.holiday.selected === '2' || self.holiday.selected === '3' ? null : self.form[0].selected,
						statecode: self.form[1].selected == '' || self.holiday.selected === '3' ? null : self.form[1].selected,
						citycode: self.form[2].selected == '' ? null : self.form[2].selected,
						dayname: self.nameedit,
						daytype: 'PH',
						daydate: moment(ev.DayDate).format("YYYY-MM-DD"),
                        activestatus: self.formedit[3].status == true ? 1 : 0
					};

					let response = await self.$store.dispatch("updateCalendar", req);
					if (response.data.code === 'AP000') {
						self.getEvents()

						self.swal.notification = true;
						self.swal.scolor = 'green';
						self.swal.message = `${ERR.HANDLE(response.data.code)}: Holiday Updated!`;
						self.dialogDate = false;
					}

					self.loading = false;
                    self.selectedOpen = false,
				    self.currentlyEditing = null
				}
			});
		},
		async deleteEvent(ev) {
			this.loading = true;
			let response = await this.$store.dispatch("deleteCalendar", {
				calendardayid: ev
			});
			if (response.data.code === 'AP000') {
				this.getEvents()

				this.swal.notification = true;
				this.swal.scolor = 'green';
				this.swal.message = `${ERR.HANDLE(response.data.code)}: Deleted!`;
				this.dialogDate = false;
			}

			else {
				this.swal.notification = true;
				this.swal.scolor = 'red';
				this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to Delete!`;
				this.dialogDate = false;
			}

			this.loading = false;
			this.selectedOpen = false,
				this.getEvents()
		},
		showEvent({
			nativeEvent,
			event
		}) {
			if (this.validateAccess('calendar_update')){
				console.log('event', event);
				const open = () => {
				this.selectedEvent = event
				this.selectedElement = nativeEvent.target
				setTimeout(() => this.selectedOpen = true, 10)
				}
				if (this.selectedOpen) {
					this.selectedOpen = false
					setTimeout(open, 10)
				} else {
					open()
				}

				//if (event.StateCode == null && event.CityCode == null) this.holidayedit.selected = '1';
				//if (event.StateCode != null && event.CityCode == null) this.holidayedit.selected = '2';
				//if (event.StateCode != null && event.CityCode != null) this.holidayedit.selected = '3';
				this.formedit[0].selected = event.CountryCode;
				this.formedit[1].selected = event.StateCode == null ? "" : event.StateCode;
				this.formedit[2].selected = event.CityCode == null ? "" : event.CityCode;

				if (event.StateCode == null && event.CityCode == null) this.holidayedit.selected = '1';
				if (event.StateCode != null) {
					this.holidayedit.selected = '2';
					const selected = this.formedit[1].itemsFull.find(itm => itm.StateCode === event.StateCode);
					this.formedit[0].selected = selected.CountryCode;
				}
				if (event.CityCode != null) {
					this.holidayedit.selected = '3';
					const selected = this.formedit[2].itemsFull.find(itm => itm.CityCode === event.CityCode);
					this.formedit[0].selected = selected.CountryCode;
					this.formedit[1].selected = selected.StateCode;
				}

				this.CreatedDateTime = event.CreatedDateTime == null ? "N/A" : moment(event.CreatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
				this.UpdatedDateTime = event.UpdatedDateTime == null ? "N/A" : moment(event.UpdatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
				this.formedit[3].status = event.ActiveStatus == 1 ? true : false;
				this.nameedit = event.DayName;
				nativeEvent.stopPropagation()
			}
		},
		updateRange({
			start,
			end
		}) {
			this.start = start
			this.end = end
		},
		nth(d) {
			return d > 3 && d < 21 ?
				'th' :
				['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
		},
		curDatetoMoment() {
			return moment(this.focus).format('ddd MMM DD YYYY');
		},
		curDatetoMomentMonthYear() {
			return moment(this.focus).format('MMMM YYYY');
		},

		validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

		setCurrentDate(){
			let month = this.month.selected;
			let monthnum = moment().month(month).format("M");

			let year = this.year.selected;
			let aa = moment().set({'date': 1, 'year': year, 'month': monthnum - 1}).format('YYYY-MM-DD');
			this.focus = aa;
		}
	}
}
</script>